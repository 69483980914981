<template>
  <div class="white pl-2 pr-2">
    <v-expansion-panels
      v-model="panels"
      flat
      multiple
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Heat Island (US Only)
            <template v-slot:tooltip>
              Per map Heat Severity - USA 2022. See resources below.
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
              class="pl-8"
            >
              <div class="mb-4">
                <div v-if="isLocatedInHeatIsland && hasHeatSeverityStatistics">
                  The project site is located within a <span class="text-warning"> severe heat zone</span>.
                </div>
                <div v-else-if="isInUSA && hasHeatSeverityStatistics">
                  The project site is not located within a severe heat area.
                </div>
                <div v-else-if="geoJSON && !hasHeatSeverityStatistics">
                  No heat severity data found associated with project location:
                  <v-btn
                    class="mt-4"
                    :loading="isHeatSeverityLoading"
                    :disabled="isHeatSeverityLoading"
                    @click="enrichProjectHeatSeverity"
                  >
                    Refresh heat severity data
                  </v-btn>
                </div>
                <div v-else>
                  No information available for heat risk in the the project site area. Current data for US only.
                </div>
              </div>
              For the health of site occupants, maximize planted areas, shade with trees and structures, and optimize the solar reflectance of materials to provide up to 10°F (5.7°C) of cooling.
            </v-col>
            <v-col
              lg="6"
              md="6"
              sm="12"
              xs="12"
            >
              <Map
                v-if="geoJSON"
                class="map"
                :image="image"
                :image-extent="imageExtent"
                :geo-json="geoJSON"
                :map-options="{blendMode: 'color-burn'}"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <ValueWithDataBook>
                <template v-slot:variable-name>
                  Building Footprint
                </template>
                <template v-slot:line2>
                  Area<br>
                  <TextFieldAndSlider
                    :disabled="true"
                    :initial-value="convertToProjectUnits(buildingFootprint)"
                    :min="0"
                    :max="convertToProjectUnits(totalArea)"
                    :show-slider="true"
                    :suffix="areaUnits"
                    :icon="iconFromType['area']"
                  />
                </template>
                <template v-slot:tooltip>
                  Sum of outer square footage of all buildings and
                  enclosed structures anticipated in final design.
                </template>
              </ValueWithDataBook>
              <ValueWithDataBook>
                <template v-slot:variable-name>
                  Site Area (beyond buildings)
                </template>
                <template v-slot:variable-value>
                  <SmartValue
                    :value="totalSiteAreaBeyondBuildings"
                    :from-unit="'m2'"
                    :to-imperial="'ft2'"
                    :to-metric="'m2'"
                  />
                </template>
                <template v-slot:tooltip>
                  This represents your final landscape area
                  outside of buildings, including all hardscape
                  and softscape.
                </template>
              </ValueWithDataBook>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="px-4 py-2">
          <CategoryHeader>
            Shade
            <template v-slot:tooltip>
              <p>
                Tree canopy targets vary. Choose
                the broad biome that most closely matches
                the biome of your project site.
              </p>
              <p>
                For additional information, see resources
                below, Why We No Longer Recommend a
                40% Urban Tree Canopy Goal. See also
                Tree Equity Score for further tree canopy
                and equity information about your site.
              </p>
            </template>
          </CategoryHeader>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          Select the broad biome of the project site:
          <v-radio-group
            v-model="projectBiome"
            @change="updateProjectBiome"
          >
            <v-radio value="forest">
              <template v-slot:label>
                <SimpleDialog>
                  <template v-slot:title>
                    About the Forest
                  </template>
                  <template v-slot:content>
                    Some text to be entered
                  </template>
                  <div
                    class="metal white--text pa-1 shade-biome-radio-text mr-2"
                  >
                    forest
                  </div>
                </SimpleDialog> Target 40-60% urban forest canopy
              </template>
            </v-radio>
            <v-radio value="grassland">
              <template v-slot:label>
                <SimpleDialog>
                  <template v-slot:title>
                    About the Grassland
                  </template>
                  <template v-slot:content>
                    Some text to be entered
                  </template>
                  <div
                    class="metal white--text pa-1 shade-biome-radio-text mr-2"
                  >
                    grassland
                  </div>
                </SimpleDialog> Target 20% urban forest canopy
              </template>
            </v-radio>
            <v-radio value="desert">
              <template v-slot:label>
                <SimpleDialog>
                  <template v-slot:title>
                    About the Desert
                  </template>
                  <template v-slot:content>
                    Some text to be entered
                  </template>
                  <div
                    class="metal white--text pa-1 shade-biome-radio-text mr-2"
                  >
                    desert
                  </div>
                </SimpleDialog> Target 15% urban forest canopy
              </template>
            </v-radio>
          </v-radio-group>
          <ValueWithDataBook :disabled="true">
            <template v-slot:variable-name>
              Total Area Shaded By Trees
            </template>
            <template v-slot:variable-value>
              <SmartValue
                :value="totalShadedArea"
                :from-unit="'m2'"
                :to-imperial="'ft2'"
                :to-metric="'m2'"
                :format="'0,0'"
              />
            </template>
            <template v-slot:tooltip>
              Calculated from trees specified in the Plants tab.
            </template>
          </ValueWithDataBook>
          <ValueWithDataBook>
            <template v-slot:variable-name>
              Total Area of Shade Structures
            </template>
            <template v-slot:line2>
              Area<br>
              <TextFieldAndSlider
                :disabled="!canEditAvailableShadedAreaByStructures"
                :initial-value="convertToProjectUnits(shadedByStructuresArea)"
                :min="0"
                :max="convertToProjectUnits(totalAvailableShadedAreaByStructures)"
                :show-slider="true"
                :suffix="areaUnits"
                :icon="iconFromType['area']"
                @change="updateShadedByStructuresArea"
              />
            </template>
            <template v-slot:tooltip>
              Sum of areas of all shade structures in <br>
              the designed landscape. May include<br>
              awnings, arcades, and free-standing<br>
              structures, soft or rigid.
            </template>
          </ValueWithDataBook>
          <v-row
            class="mt-6 mb-4"
          >
            <v-col
              class="text-center middle-align pa-12"
              cols="6"
            >
              <div v-if="percentShadedByTrees * 100 >= expectedShadedArea">
                Great! <SmartValue
                  :value="percentShadedByTrees * 100"
                  style="color: #00ADEE"
                  :format="'0,0'"
                  :append="'%'"
                /> of the site is shaded by trees.
              </div>
              <div v-else-if="percentShadedByTrees !== null">
                Only <SmartValue
                  :value="percentShadedByTrees * 100"
                  class="red--text"
                  :format="'0,0'"
                />% of the site is shaded by trees. Consider adding structures and trees to provide more shade.
              </div>
              <div v-else>
                Project Area is not yet defined.
              </div>
            </v-col>
            <v-col cols="6">
              <ShadedChart
                :series-data="donutSeriesData"
                :height="'130px'"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters as mapGlobalGetters } from 'vuex'
import CategoryHeader from '@/components/atoms/CategoryHeader.vue'
import { convertFromProjectSystem, convertProjectSystem } from '@/helpers/unitConversions'
import Map from './Map.vue'
import { iconFromType } from '@/schema/units'
import Vue from 'vue'
import SimpleDialog from '@/components/modals/SimpleDialog.vue'
import ValueWithDataBook from '@/components/molecules/ValueWithDataBook.vue'
import SmartValue from '@/components/atoms/SmartValue.vue'
import TextFieldAndSlider from '@/components/atoms/TextFieldAndSlider.vue'
import unitTools from '@/mixins/unitTools'
import ShadedChart from '@/components/charts/ShadedChart.vue'
import { debounce } from '@/helpers/debounce'

const { mapGetters: mapProjectGetters, mapActions: mapProjectActions } = createNamespacedHelpers('projects')
const { mapGetters: mapVersionGetters, mapActions: mapVersionActions } = createNamespacedHelpers('projects/versions')

export default Vue.extend({
  components: {
    TextFieldAndSlider,
    SmartValue,
    CategoryHeader,
    SimpleDialog,
    ValueWithDataBook,
    ShadedChart,
    Map
  },
  mixins: [unitTools],
  data () {
    return {
      geoJSON: null,
      iconFromType,
      shadedByStructuresArea: null,
      buildingFootprint: null,
      projectBiome: null,
      panels: [0],
      isEnrichDataLoading: false,
      updatingServer: false
    }
  },
  computed: {
    ...mapProjectGetters(['project', 'isLocatedInHeatIsland', 'hasHeatSeverityStatistics', 'isEnrichingData']),
    ...mapVersionGetters(['version', 'statsLoading']),
    ...mapGlobalGetters(['projectBiomes']),
    totalAvailableShadedAreaByStructures: function () {
      const available = this.totalSiteAreaBeyondBuildings - this.totalShadedArea
      if (available >= 0) {
        return available
      }
      return 0
    },
    totalSiteAreaBeyondBuildings: function () {
      const available = this.totalArea - (this.buildingFootprint || 0)
      if (available >= 0) {
        return available
      }
      return 0
    },
    isHeatSeverityLoading () {
      return this.isEnrichingData || this.isEnrichDataLoading
    },
    canEditAvailableShadedAreaByStructures: function () {
      return this.expectedShadedArea !== undefined
    },
    expectedShadedArea: function () {
      return this.projectBiomes?.[this.projectBiome]?.['targetShadePercent'].min
    },
    totalArea: function () {
      return this['project'].location.area || 0
    },
    totalShadedArea: function () {
      return this['version']?.stats?.totals?.shadedArea
    },
    totalUnshadedArea: function () {
      return this.totalSiteAreaBeyondBuildings - this.totalShadedArea - this.shadedByStructuresArea
    },

    reductionFromBaseline: function () {
      return 82
    },
    showCongratulations: function () {
      return this.reductionFromBaseline > 30
    },
    image: function () {
      return this['project'].enrichedData.heatImage?.parsedResponse.base64
    },
    imageExtent: function () {
      return this['project'].enrichedData.heatImage?.query.extent
    },
    isInUSA: function () {
      return this['project'].location.country === 'United States'
    },
    percentShadedByTrees: function () {
      if (this.totalSiteAreaBeyondBuildings > 0) {
        return this.totalShadedArea / this.totalSiteAreaBeyondBuildings
      }
      return null
    },
    donutSeriesData () {
      const seriesData = [['Trees', this.totalShadedArea], ['Unshaded', this.totalUnshadedArea], ['Structures', this.shadedByStructuresArea]]
      return seriesData
    }
  },
  async created () {
    const { data } = await this.$axios.get(`/projects/${this['project']._id}/location`)
    this.shadedByStructuresArea = this['version'].attributes['shadedByStructuresArea'] || 0
    this.projectBiome = this['version'].attributes['projectBiome']
    this.buildingFootprint = this['project'].location['buildingFootprint']
    this.geoJSON = data
  },
  methods: {
    ...mapVersionActions(['fetchVersion', 'setVersion']),
    ...mapProjectActions(['enrichProject']),
    mergeAttributes () {
      return {
        ...this['version'].attributes,
        projectBiome: this.projectBiome,
        shadedByStructuresArea: this.shadedByStructuresArea
      }
    },
    convertToProjectUnits (value) {
      return convertProjectSystem(
        {
          v: value,
          project: this['project'],
          from: 'm2',
          toMetric: 'm2',
          toImperial: 'ft2'
        }
      )?.value
    },
    enrichProjectHeatSeverity: async function () {
      this.isEnrichDataLoading = true
      await this.enrichProject(['heat_severity'])
      this.isEnrichDataLoading = false
    },
    updateProjectBiome: function (projectBiome) {
      // there must be a way to do a partial update
      // TODO: fix the updateVersion controller to support nested updates.
      return this.updateServer({
        attributes: {
          ...this.mergeAttributes(),
          projectBiome
        }
      })
    },
    updateShadedByStructuresArea: function (area) {
      const baseValue = convertFromProjectSystem({ v: area, project: this['project'], to: 'm2', fromMetric: 'm2', fromImperial: 'ft2' })
      this.shadedByStructuresArea = baseValue
      // there must be a way to do a partial update
      // TODO: fix the updateVersion controller to support nested updates.
      return this.updateServer({
        attributes: {
          ...this.mergeAttributes(),
          shadedByStructuresArea: baseValue
        }
      })
    },
    updateServer: debounce(async function (partial) {
      this.updatingServer = true
      await this.$axios.put(`/versions/${this['version']._id}`, partial)
      await this.fetchVersion({ id: this['version']._id })
      this.updatingServer = false
    }, 1000)

  }
})
</script>

<style lang="scss">
.stats-panel .v-expansion-panel-content__wrap {
  padding: 0;
}
.text-warning {
  color: red;
}
.new-element-form {
  position: relative;
  --box-shadow-color: var(--v-shamrock-base);
}
.shade-biome-radio-text {
  text-align: center;
  width: 100px;
}
.middle-align {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
